<template>
  <b-overlay :show="isFetching || isDeleting">
    <b-card>
      <CashbackForm
        v-model="cashbackSettingByAgentId"
        :is-loading="isAdding || isUpdating"
        @submit="onSubmit"
        @cancel="onCloseModal"
      />
    </b-card>
  </b-overlay>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'CashbackAgent',
  components: {
    CashbackForm: () => import('../forms/cashback-form'),
  },
  data() {
    return {}
  },
  computed: {
    ...mapState({
      isFetching: (state) => state.cashback.isFetchingCashbackSettings,
      isAdding: (state) => state.cashback.isAddingCashbackSettings,
      isUpdating: (state) => state.cashback.isUpdatingCashbackSettings,
      isDeleting: (state) => state.cashback.isDeletingCashbackSettings,
    }),
    ...mapGetters(['userInfo', 'cashbackSettingByAgentId']),
    agentId() {
      return this.userInfo.agentId
    },
  },
  watch: {
    agentId(val) {
      if (val) {
        this.fetchData()
      }
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions([
      'fetchCashbackSettingByAgentId',
      'addCashbackSettings',
      'updateCashbackSettings',
    ]),
    fetchData() {
      if (this.agentId) {
        this.fetchCashbackSettingByAgentId(this.agentId)
      }
    },
    toggleModal(value) {
      this.isModalActive = !this.isModalActive
      this.selectedValue = value
    },
    onCloseModal() {
      this.isModalActive = false
    },
    async onSubmit(form) {
      if (form.id) {
        if (!this.isMaster) {
          form.agentId = this.userInfo.agentId
          form.agentUsername = this.userInfo.username
        }
        await this.updateCashbackSettings({
          id: form.id,
          form,
        })
        this.onCloseModal()
      } else {
        if (!this.isMaster) {
          form.agentId = this.userInfo.id
          form.agentUsername = this.userInfo.username
        }
        await this.addCashbackSettings(form)
        this.onCloseModal()
      }
    },
  },
}
</script>
